const style = {
  base: {
    fontSize: "18px"
  }
};

class CreditCardForm {
  constructor(card) {
    this.card = card;
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }

  prepare() {
    const cardElement = document.querySelector(".js-card-element");
    this.formElement = document.querySelector(".js-credit-card-form");
    this.cardErrors = this.formElement.querySelector(".js-card-errors");
    this.button = this.formElement.querySelector(".js-button");
    this.card.addEventListener("ready", () => { this.card.focus(); });
    this.card.mount(cardElement);
  }

  url() {
    return this.formElement.action;
  }

  onChange(fn) {
    this.card.addEventListener("change", fn);
  }

  displayErrorMessage(error) {
    this.cardErrors.textContent = error.message;
  }

  clearErrorMessage() {
    console.log("clear");
    this.cardErrors.textContent = "";
  }

  onSubmit(fn) {
    this.formElement.addEventListener("submit", (event) => {
      event.preventDefault();
      fn();
    });
  }

  displayLoadingIndicator() {
    this.button.classList.add("btn-loading");
    this.button.setAttribute("disabled", true);
  }

  clearLoadingIndicator() {
    this.button.classList.remove("btn-loading");
    this.button.removeAttribute("disabled");
  }

  navigateTo(href) {
    window.location.href = href;
  }

  displayErrorNotification(message) {
    const existingNotification = this.formElement.querySelector(".js-error-notification");
    if (existingNotification) { existingNotification.remove(); }
    const notification = document.createElement("p");
    notification.classList.add("notice", "notice-warning", "js-error-notification");
    notification.textContent = message;
    this.formElement.insertAdjacentElement("afterbegin", notification);
  }
}

async function submitCreditCardToken(creditCardForm, creditCardTokenId) {
  var cloudResponse = creditCardForm['formElement'][1]['value'];
  try {
    const response = await fetch(creditCardForm.url(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": creditCardForm.csrfToken()
      },
      body: JSON.stringify({ credit_card_token: creditCardTokenId, cloudToken: cloudResponse }),
      credentials: "same-origin"
    });

    const data = await response.json();

    if (response.ok) {
      return { ok: true, location: data.location };
    } else {
      return { ok: false, message: data.message };
    }
  } catch (e) {
    return { ok: false, message: "An unexpected error occurred" };
  }
}

function SetupCreditCardForm(publishableKey) {
  const stripe = Stripe(publishableKey);
  const stripeElements = stripe.elements();
  const card = stripeElements.create("card", {style: style});
  const creditCardForm = new CreditCardForm(card);

  creditCardForm.prepare();

  creditCardForm.onChange(({error}) => {
    if (error) {
      creditCardForm.displayErrorMessage(error);
    } else {
      creditCardForm.clearErrorMessage();
    }
  });

  creditCardForm.onSubmit(async () => {
    creditCardForm.displayLoadingIndicator();

    const {token, error} = await stripe.createToken(card);

    if (error) {
      creditCardForm.clearLoadingIndicator();
    } else {
      const result = await submitCreditCardToken(creditCardForm, token.id);

      if (result.ok) {
        creditCardForm.navigateTo(result.location);
      } else {
        creditCardForm.clearLoadingIndicator();
        creditCardForm.displayErrorNotification(result.message);
      }
    }
  });
}

export default SetupCreditCardForm;
