function addLoadingClassOnSubmit(formSelector) {
  const form = document.querySelector(formSelector);
  const button = form.querySelector(".btn-primary");
  form.addEventListener("submit", () => {
    button.classList.add("btn-loading");
    button.setAttribute("disabled", true);
  });
}

export default addLoadingClassOnSubmit;
